//tarek
import { useState, useEffect } from 'react';
import '../assets/styles/index.css';
import {
  Badge,
  Navbar,
  Nav,
  NavDropdown,
  Container,
  Row,
  Col,
  Form,
  Button,
} from 'react-bootstrap';
import {
  FaJenkins,
  FaUser,
  FaUserLock,
  FaFlag,
  FaMoneyBill,
  FaCartPlus,
} from 'react-icons/fa';
import { LinkContainer } from 'react-router-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import SearchBox from './SearchBox';
import { useLogoutMutation } from '../slices/usersApiSlice';
import { logout } from '../slices/authSlice';

//tarek
import { setCredentials } from '../slices/authSlice';
import { toast } from 'react-toastify';
import { useLoginMutation } from '../slices/usersApiSlice';

//couleur
//import { Us, Fr, Tn } from 'react-flags-select';
import { useTranslation } from 'react-i18next';

//tarek3
import { removeFromCartLocalStorage } from '../slices/cartSlice';

//couleur
import { setCredentialLangue } from '../slices/langueSlice';

import { setCredentialDevise } from '../slices/deviseSlice';

var espace = '/';
//tarek
//const mail = "user@user";
//var pass1 = "$2a$10$SdNv/cpINi6EDuz/A/aJouHctlyuArh.aaTeXefupQeRECirRMHl2"
//const pass = "user";

const HeaderDesktop = () => {
  //selim
  const [colorFormationPresential, setColorFormationPresential] = useState(
    'formation_presential_green'
  );

  const [colorPanier, setColorPanier] = useState('panier_red');

  //depuis le store
  const { cartItems } = useSelector((state) => state.cart);

  //tarek
  const [email, setEmail] = useState('user@user');
  const [password, setPassword] = useState('user');

  const [login] = useLoginMutation();
  const { userInfo } = useSelector((state) => state.auth);

  //tarek
  const { search } = useLocation();
  const sp = new URLSearchParams(search);
  const redirect = sp.get('redirect') || '/';

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { i18n, t } = useTranslation();

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };
  //couleur
  const { langueInfo } = useSelector((state) => state.langue);

  const { deviseInfo } = useSelector((state) => state.devise);
  //const [langue, setLangue] = useState("FR");
  const [colorAuthentificationUser, setColorAuthentificationUser] = useState(
    'colorAuthentificationUser_green'
  );

  //tarek
  useEffect(() => {
    if (userInfo) {
      //tarek2
      setColorFormationPresential('formation_presential_green');
      setColorPanier('panier_green');
      //selim
      setColorAuthentificationUser('colorAuthentificationUser_red');

      navigate(redirect);
    }
  }, [userInfo, redirect, navigate]);

  //const connexion_invite = () => {
  //navigate('/login?redirect=/shipping');
  //navigate('/cart');
  //}

  //tarek
  const connexion_invite = async () => {
    //e.preventDefault();
    try {
      //tarek2 css
      setColorFormationPresential('formation_presential_green');
      setColorPanier('panier_green');

      setEmail('user@user');
      setPassword('user');
      //console.log("mail"+mail);
      //console.log("pass"+pass);
      //console.log(email);
      //console.log(password);
      const res = await login({ email, password }).unwrap();
      //console.log(res);
      dispatch(setCredentials({ ...res }));
      navigate(redirect);
    } catch (err) {
      toast.error(err?.data?.message || err.error);
    }
  };

  const [logoutApiCall] = useLogoutMutation();

  const logoutHandler = async () => {
    try {
      //selim
      setColorFormationPresential('formation_presential_green');

      setColorPanier('panier_red');

      await logoutApiCall().unwrap();
      dispatch(logout());

      //tarek3
      dispatch(removeFromCartLocalStorage());

      navigate('/');
      //tarek3  refresh
      window.location.reload();
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <header>
      <Container className='container1-row'>
        <Row className='row1_navbar'>
          <Col md={2} className='column_log_formateur'>
            {/*<LinkContainer to='/login'>*/}
            {userInfo && !userInfo.isAdmin && !userInfo.isOffice && (
              <NavDropdown
                style={{ color: 'green', fontWeight: 'bold' }}
                title={userInfo.name}
                id='username'
              >
                <NavDropdown.Item onClick={logoutHandler}>
                  {t('Logout')}
                </NavDropdown.Item>
              </NavDropdown>
            )}

            {!userInfo && (
              <Nav.Link style={{ color: 'red', fontWeight: 'bold' }}>
                <FaUser /> {t('guest')}
                <Button
                  type='button'
                  variant='light'
                  onClick={connexion_invite}
                >
                  <FaJenkins />
                </Button>
              </Nav.Link>
            )}

            {/* </LinkContainer> */}

            {userInfo && userInfo.isAdmin && (
              <NavDropdown
                title='Admin'
                id='adminmenu'
                style={{ color: 'green', fontWeight: 'bold' }}
              >
                <LinkContainer to='/admin/productlist'>
                  <NavDropdown.Item>Produits</NavDropdown.Item>
                </LinkContainer>
                <LinkContainer to='/admin/orderlist'>
                  <NavDropdown.Item>Commandes</NavDropdown.Item>
                </LinkContainer>
                <LinkContainer to='/admin/userlist'>
                  <NavDropdown.Item>Utilisateurs</NavDropdown.Item>
                </LinkContainer>
                <LinkContainer to='/admin/cert'>
                  <NavDropdown.Item>Certificate Generator</NavDropdown.Item>
                </LinkContainer>
                <LinkContainer to='/admin/verifycert'>
                  <NavDropdown.Item>
                    {' '}
                    Certificate Verification{' '}
                  </NavDropdown.Item>
                </LinkContainer>
                <LinkContainer to='/meet'>
                  <NavDropdown.Item>Meet</NavDropdown.Item>
                </LinkContainer>
                <LinkContainer to='/admin/playlist'>
                  <NavDropdown.Item> Playlist </NavDropdown.Item>
                </LinkContainer>
                <LinkContainer to='/admin/click2paytable'>
                  <NavDropdown.Item> clic2pay Table </NavDropdown.Item>
                </LinkContainer>
                <NavDropdown.Item onClick={logoutHandler}>
                  Déconnexion
                </NavDropdown.Item>
              </NavDropdown>
            )}

            {userInfo && !userInfo.isAdmin && userInfo.isOffice && (
              <NavDropdown
                title='Office'
                id='officemenu'
                style={{ color: 'green', fontWeight: 'bold' }}
              >
                <LinkContainer to='/office/productlist'>
                  <NavDropdown.Item>Produits</NavDropdown.Item>
                </LinkContainer>
                <LinkContainer to='/office/cert'>
                  <NavDropdown.Item>Certificate Generator</NavDropdown.Item>
                </LinkContainer>
                <LinkContainer to='/office/verifycert'>
                  <NavDropdown.Item>
                    {' '}
                    Certificate Verification{' '}
                  </NavDropdown.Item>
                </LinkContainer>
                <LinkContainer to='/profile'>
                  <NavDropdown.Item>Commandes</NavDropdown.Item>
                </LinkContainer>
                {/* <LinkContainer to='/office/playlist'>
                  <NavDropdown.Item> Playlist </NavDropdown.Item>
                </LinkContainer> */}
                <LinkContainer to='/office/click2paytable'>
                  <NavDropdown.Item> clic2pay Table </NavDropdown.Item>
                </LinkContainer>
                <NavDropdown.Item onClick={logoutHandler}>
                  Déconnexion
                </NavDropdown.Item>
              </NavDropdown>
            )}
          </Col>

          {/* <Col md={2} className="column_langue">
                 <Nav className="langue"><FaFlag/>{espace} {t('language')}</Nav>
                 <NavDropdown>  
                 <NavDropdown.Item onClick={() => changeLanguage('en')}>
              English <Us />
            </NavDropdown.Item>
            <NavDropdown.Item onClick={() => changeLanguage('fr')}>
              Français <Fr />
            </NavDropdown.Item>
            <NavDropdown.Item onClick={() => changeLanguage('ar')}>
              عربي <Tn />
            </NavDropdown.Item>
                 </NavDropdown>
              </Col> */}

          {/*  couleur */}
          <Col md={2} className='column_langue'>
            {/*  couleur */}
            {/* <Nav className="langue" style={{ color: 'green', fontWeight: 'bold' }} ><FaFlag/>{espace} Langue | {langueInfo}</Nav>  */}
            <Nav
              className='langue'
              style={{ color: 'green', fontWeight: 'bold' }}
            >
              <FaFlag />
              {espace} <h6>{t('language')}</h6>
            </Nav>
            <Form.Control
              as='select'
              value={langueInfo}
              //onChange={(e) => dispatch(setCredentialLangue(e.target.value))}
              //couleur (deux fonctions appelées)
              onChange={(e) => {
                dispatch(setCredentialLangue(e.target.value));
                changeLanguage(e.target.value);
              }}
              //onChange = { (e) => { this.activarBotonEnviar(e); this.contadorDeCaracteres(e) } }
            >
              <option key='fr' value='fr'>
                Français
              </option>
              <option key='en' value='en'>
                English
              </option>
              <option key='ar' value='ar'>
                عربي
              </option>
            </Form.Control>
          </Col>

          <Col md={2} className='column_devise'>
            {/*  couleur */}
            {/* <Nav className="devise" style={{ color: 'green', fontWeight: 'bold' }} ><FaMoneyBill/>{espace} Devise | {deviseInfo}</Nav>
             */}
            <Nav
              className='devise'
              style={{ color: 'green', fontWeight: 'bold' }}
            >
              <FaMoneyBill />
              {espace} <h6>{t('devise')}</h6>{' '}
            </Nav>
            {/*  selim */}
            <Form.Control
              as='select'
              value={deviseInfo}
              onChange={(e) => dispatch(setCredentialDevise(e.target.value))}
            >
              <option key='D_tn' value='D_tn'>
                D_tn
              </option>
              <option key='Euro' value='Euro'>
                Euro
              </option>
              <option key='Dolar' value='Dolar'>
                Dollar
              </option>
            </Form.Control>
          </Col>

          <Col md={4} className='column_formations'>
            <LinkContainer to='/'>
              <Navbar.Text
                className='formation_en_ligne center'
                style={{
                  Color: 'green',
                }}
              >
                <h6>{t('online')}</h6>{' '}
              </Navbar.Text>
            </LinkContainer>
            <hr />
            <LinkContainer to='/'>
              {/* couleur */}
              <Nav.Link>
                {' '}
                <h6 className={colorFormationPresential}>
                  {t('inperson')}
                </h6>{' '}
              </Nav.Link>
            </LinkContainer>
          </Col>

          <Col md={2} className='column_log_user'>
            <LinkContainer to='/login'>
              {/* selim */}
              <Nav.Link>
                {' '}
                <h5 className={colorAuthentificationUser}>
                  <FaUserLock /> {t('user')}{' '}
                </h5>{' '}
              </Nav.Link>
            </LinkContainer>
          </Col>
        </Row>
      </Container>

      <Container>
        <Row className='row_licorne'>
          <Col md={3} className='column_categories'>
            <NavDropdown title={t('category')} id='basic-nav-dropdown'>
              <NavDropdown.Item>{t('info')}</NavDropdown.Item>
              <NavDropdown.Item>{t('devp')}</NavDropdown.Item>
              <NavDropdown.Item>{t('politique')}</NavDropdown.Item>
            </NavDropdown>
          </Col>

          <Col md={5} className='column_search py-5'>
            {/* <Form className="d-flex"> */}
            <SearchBox />
            {/* </Form> */}
          </Col>

          <Col md={2} className='column_licorne'>
            <LinkContainer to='/'>
              <Navbar.Brand>
                <img
                  src='../../images/licorne.png'
                  className='d-inline-block align-top'
                  alt='React Bootstrap logo'
                  width='160'
                  height='130'
                />
              </Navbar.Brand>
            </LinkContainer>
          </Col>

          <Col md={2} className='column_panier'>
            <LinkContainer to='/cart'>
              {/* color */}
              <Nav className={colorPanier}>
                <FaCartPlus />
                {espace} <h6>{t('cart')}</h6>
                {cartItems.length > 0 && (
                  <Badge pill bg='success' style={{ marginLeft: '5px' }}>
                    {cartItems.reduce((a, c) => a + c.qty, 0)}
                  </Badge>
                )}
              </Nav>
            </LinkContainer>
          </Col>
        </Row>
      </Container>
    </header>
  );
};

export default HeaderDesktop;
