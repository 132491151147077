import React, { useState, useEffect } from 'react';
import { Col, Card, Button, Modal, Form, Spinner, Row } from 'react-bootstrap';
import axios from 'axios';
import { useNavigate, Link } from 'react-router-dom';
import './Videos.css';
import { useSelector } from 'react-redux';
const Playlist = () => {
  const [playlists, setPlaylists] = useState([]);
  const [selectedPlaylist, setSelectedPlaylist] = useState(null);
  const [newPlaylistName, setNewPlaylistName] = useState('');
  const [newPlaylistDescription, setNewPlaylistDescription] = useState('');
  const [newPlaylistImage, setNewPlaylistImage] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const { userInfo } = useSelector((state) => state.auth);
  const fetchPlaylists = async () => {
    if (userInfo && (userInfo.isAdmin || userInfo.isOffice)) {
      try {
        const response = await axios.get('/api/playlists', {
          withCredentials: true,
        });
        setPlaylists(response.data);
      } catch (error) {
        if (error.response && error.response.status === 401) {
          navigate('/login'); // Redirect to login page on unauthorized
        }
        console.error('Error fetching playlists:', error.message);
      }
    } else {
      // Redirect to login page if not admin or office
      navigate('/login');
    }
  };

  const handlePlaylistAdd = async () => {
    if (userInfo && (userInfo.isAdmin || userInfo.isOffice)) {
      const formData = new FormData();
      formData.append('name', newPlaylistName);
      formData.append('description', newPlaylistDescription);
      formData.append('image', newPlaylistImage);

      try {
        setLoading(true);
        // Corrected Axios request
        await axios.post('/api/playlists', formData, {
          withCredentials: true, // This should be part of the third argument object
        });
        fetchPlaylists();
        handleCloseModal();
      } catch (error) {
        setError('Failed to add playlist. Please try again.');
        console.error('Error adding playlist:', error.message);
      } finally {
        setLoading(false);
      }
    } else {
      // Redirect to login page if not admin or office
      navigate('/login');
    }
  };

  const handlePlaylistDelete = async (playlistId) => {
    if (userInfo && (userInfo.isAdmin || userInfo.isOffice)) {
      try {
        setLoading(true);

        // Send a DELETE request to delete the playlist
        await axios.delete(`/api/playlists/${playlistId}`, {
          withCredentials: true,
        });
        navigate('/');

        // Fetch the updated list of playlists
        // fetchPlaylists();
      } catch (error) {
        console.error('Error deleting playlist:', error.message);
      } finally {
        setLoading(false);
      }
    } else {
      // Redirect to login page if not admin or office
      navigate('/login');
    }
  };
  const handlePlaylistClick = (playlistId) => {
    if (userInfo && (userInfo.isAdmin || userInfo.isOffice)) {
      setSelectedPlaylist(playlistId); // Store the selected playlist ID
      navigate(`/admin/playlists/${playlistId}/videos`); // Navigate using the playlist ID
    } else {
      navigate('/login'); // Redirect to login page if not admin or office
    }
  };

  useEffect(() => {
    if (userInfo && (userInfo.isAdmin || userInfo.isOffice)) {
      fetchPlaylists();
    } else {
      // Redirect to login page if not admin or office
      navigate('/login');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleShowModal = (type) => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setNewPlaylistName('');
    setNewPlaylistDescription('');
    setNewPlaylistImage(null);
  };

  return (
    <Col>
      <h1>Playlist Management</h1>
      {error && <p className='error'>{error}</p>}
      <Row>
        {playlists.map((playlist) => (
          <Col key={playlist._id} md={4}>
            <Card
              style={{
                backgroundColor:
                  selectedPlaylist === playlist._id ? '#f0f0f0' : 'white',
              }}
              onClick={() => handlePlaylistClick(playlist._id)} // Pass playlist._id here
            >
              <Card.Img
                style={{
                  maxWidth: '100%',
                  height: '350px',
                  objectFit: 'cover',
                }}
                variant='top'
                src={`${playlist.image}`}
              />
              <Card.Body>
                <Card.Title>{playlist.name}</Card.Title>
                <Card.Text>{playlist.description}</Card.Text>
                <Button
                  variant='danger'
                  onClick={() => handlePlaylistDelete(playlist._id)}
                >
                  Delete Playlist
                </Button>

                {/* Add a Link to the videos page of the selected playlist */}
                <Link to={`/admin/playlists/${playlist._id}/videos`}>
                  <Button variant='info'>View Videos</Button>
                </Link>
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>
      <Button variant='success' onClick={() => handleShowModal('AddPlaylist')}>
        Add Playlist
      </Button>

      {/* Modal for adding a playlist */}
      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Add Playlist</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId='playlistName'>
              <Form.Label>Playlist Name</Form.Label>
              <Form.Control
                type='text'
                value={newPlaylistName}
                onChange={(e) => setNewPlaylistName(e.target.value)}
              />
            </Form.Group>
            <Form.Group controlId='playlistDescription'>
              <Form.Label>Playlist Description</Form.Label>
              <Form.Control
                type='text'
                value={newPlaylistDescription}
                onChange={(e) => setNewPlaylistDescription(e.target.value)}
              />
            </Form.Group>
            <Form.Group controlId='playlistImage'>
              <Form.Label>Playlist Image</Form.Label>
              <Form.Control
                type='file'
                onChange={(e) => setNewPlaylistImage(e.target.files[0])}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' onClick={handleCloseModal}>
            Close
          </Button>
          <Button
            variant='primary'
            onClick={handlePlaylistAdd}
            disabled={loading}
          >
            {loading && (
              <Spinner
                as='span'
                animation='border'
                size='sm'
                role='status'
                aria-hidden='true'
              />
            )}{' '}
            Add Playlist
          </Button>
        </Modal.Footer>
      </Modal>
    </Col>
  );
};

export default Playlist;
