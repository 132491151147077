import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useLocation } from 'react-router-dom';
import { Container, Alert } from 'react-bootstrap';

const SuccessStripe = () => {
  const [message, setMessage] = useState('');
  const location = useLocation();

  useEffect(() => {
    const query = new URLSearchParams(location.search);
    const session_id = query.get('session_id');

    const verifyPayment = async () => {
      try {
        const response = await axios.get(
          `/api/success?session_id=${session_id}`
        );
        setMessage(response.data.message);
      } catch (error) {
        setMessage('Transaction verification failed');
      }
    };

    if (session_id) {
      verifyPayment();
    }
  }, [location]);

  return (
    <Container>
      <h1 className='text-center'>Success</h1>
      <Alert variant={message.includes('successful') ? 'success' : 'danger'}>
        {message}
      </Alert>
    </Container>
  );
};

export default SuccessStripe;
