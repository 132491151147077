import React, { useState, useEffect } from 'react';
import { Row, Button, Modal, Form, Spinner } from 'react-bootstrap';
import axios from 'axios';
import ReactPlayer from 'react-player';
import { useParams, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import './Videos.css';

const Videos = () => {
  const [playlist, setPlaylist] = useState(null);
  const [videos, setVideos] = useState([]);
  const [newVideoName, setNewVideoName] = useState('');
  const [videoFile, setVideoFile] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedVideo, setSelectedVideo] = useState(null); // State to keep track of the selected video
  const { playlistId } = useParams();
  const { userInfo } = useSelector((state) => state.auth);
  const navigate = useNavigate();

  // Function to fetch videos with authorization
  const fetchVideos = async () => {
    if (userInfo && (userInfo.isAdmin || userInfo.isOffice)) {
      try {
        const response = await axios.get(
          `/api/playlists/${playlistId}/videos`,
          {
            withCredentials: true,
          }
        );
        setVideos(response.data);
        // Auto-select the first video for auto-play
        if (response.data.length > 0) {
          setSelectedVideo(response.data[0]);
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          navigate('/login');
        }
        console.error('Error fetching videos:', error.message);
      }
    } else {
      navigate('/login');
    }
  };

  useEffect(() => {
    // Ensure playlistId is a valid string
    if (!playlistId || typeof playlistId !== 'string') {
      navigate('/login');
      return;
    }

    // Fetch playlist
    const fetchPlaylist = async () => {
      if (userInfo && (userInfo.isAdmin || userInfo.isOffice)) {
        try {
          const response = await axios.get(`/api/playlists/${playlistId}`, {
            withCredentials: true,
          });
          setPlaylist(response.data);
        } catch (error) {
          console.error('Error fetching playlist:', error.message);
        }
      } else {
        navigate('/login');
      }
    };

    fetchVideos();
    fetchPlaylist();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [playlistId, userInfo, navigate]);

  const handleVideoAdd = async () => {
    if (userInfo && (userInfo.isAdmin || userInfo.isOffice)) {
      try {
        setLoading(true);

        const formData = new FormData();
        formData.append('name', newVideoName);
        formData.append('video', videoFile);

        await axios.post(`/api/playlists/${playlistId}/videos`, formData, {
          withCredentials: true,
        });

        fetchVideos();
        handleCloseModal();
      } catch (error) {
        console.error('Error adding video:', error.message);
      } finally {
        setLoading(false);
      }
    } else {
      navigate('/login');
    }
  };

  const handleVideoDelete = async (videoId) => {
    if (userInfo && (userInfo.isAdmin || userInfo.isOffice)) {
      try {
        await axios.delete(`/api/playlists/${playlistId}/videos/${videoId}`, {
          withCredentials: true,
        });
        fetchVideos();
      } catch (error) {
        console.error('Error deleting video:', error.message);
      }
    } else {
      navigate('/login');
    }
  };

  const handleShowModal = () => setShowModal(true);

  const handleCloseModal = () => {
    setShowModal(false);
    setNewVideoName('');
    setVideoFile(null);
  };

  const handleVideoClick = (video) => {
    setSelectedVideo(video);
  };

  return (
    <>
      <h2>Videos in Playlist: {playlist?.name}</h2>
      <Button
        variant='success'
        onClick={handleShowModal}
        style={{ marginBottom: '10px' }}
      >
        Add Video
      </Button>
      <div className='sam'>
        {userInfo && userInfo.isAdmin && (
          <Row className='playlist-container'>
            <div className='leftplaylist'>
              {selectedVideo && (
                <ReactPlayer
                  className='videos'
                  url={`${selectedVideo.path}`}
                  controls
                  playing={true} // Auto-play the selected video
                  config={{
                    file: {
                      attributes: {
                        controlsList: 'nodownload', // Disable download button
                        disablePictureInPicture: true, // Disable Picture-in-Picture mode
                        onContextMenu: (e) => e.preventDefault(), // Disable right-click menu on the video
                      },
                    },
                  }}
                />
              )}
            </div>
            <div className='rightplaylist'>
              <h5> Videos in The Playlist :</h5>
              {videos.map((video) => (
                <div
                  key={video._id}
                  className='playlist-item'
                  onClick={() => handleVideoClick(video)}
                >
                  <p>{video.name}</p>
                  <Button
                    variant='danger'
                    onClick={() => handleVideoDelete(video._id)}
                  >
                    Delete
                  </Button>
                </div>
              ))}
            </div>
          </Row>
        )}
      </div>

      {/* Modal for adding a video */}
      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Add Video</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId='videoName'>
              <Form.Label>Video Name</Form.Label>
              <Form.Control
                type='text'
                value={newVideoName}
                onChange={(e) => setNewVideoName(e.target.value)}
              />
            </Form.Group>
            <Form.Group controlId='videoFile'>
              <Form.Label>Video File</Form.Label>
              <Form.Control
                type='file'
                onChange={(e) => setVideoFile(e.target.files[0])}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' onClick={handleCloseModal}>
            Close
          </Button>
          <Button variant='primary' onClick={handleVideoAdd} disabled={loading}>
            {loading && (
              <Spinner
                as='span'
                animation='border'
                size='sm'
                role='status'
                aria-hidden='true'
              />
            )}{' '}
            Add Video
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Videos;
